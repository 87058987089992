


// 
// const api='http://localhost:4000'
const api='https://livesite-backend-eyfd.onrender.com'



//
export default api;
